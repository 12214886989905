/* vietnamese */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B6x7T2kn3.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B6x_T2kn3.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B6xHT2g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  

  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: var(--dark-purple);
    color: #fff;
  }

  .nav-title{ 
    font-family:'Barlow Condensed';
    font-size: large;
    text-decoration: none;
    color:var(--light-purple);
    margin-right: 30px;
    margin-left: 20px;
  }
  
  .nav-title:hover {
      color: #e217d8;
  }

  .active {
    text-decoration: none;
    position: relative;
  }
  
  .active::after {
    content: '';
    display: block;
    height: 2px;
    width: 120%;
    background-color: var(--light-purple);
    position: absolute;
    bottom: -12px;
    left: -10%;
  }
  
  .nav-item {
    list-style-type: none;
    display: inline-block;
    margin-right: 15px; /* Optional: to give some space between items */
  }

  .flex-container {
    display: flex;
    align-items: center;
  }
  
  .navbar-nav {
    justify-content: flex-start !important;
  }