/* vietnamese */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B6x7T2kn3.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B6x_T2kn3.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B6xHT2g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  :root {
    --dark-purple: #400140;
    --medium-purple: #9c119c;
    --light-purple: #eecdee;
    --graph-purple: #eb92eb;
  }
  
  .txt-body{ 
    font-family:'Barlow Condensed';
    font-size:larger;
    text-decoration: none;
    color: var(--dark-purple);
    margin-right: 30px;
  }
  
  .card-main{
    background-image: linear-gradient(to bottom right, var(--light-purrple) , var(--dark-purple));
  }
  s
  .card{
    background-color: #ffffff;
    border-top: 4px solid var(--light-purple);
  }
  
  .btn-secondary{
    text-decoration: none;
    background-color: var(--light-purple);
    color: var(--dark-purple);
    width: 150px;
  }
  
  .btn-secondary:hover{
    background-color: var(--dark-purple);
    color: var(--light-purple);
  }
  
  
  .btn-practice{
    text-decoration: none;
    background-color: var(--light-purple);
    height: 120px;
    width: 170px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .btn-practice:hover{
    background-color: var(--dark-purple);
    color: var(--dark-purple);
  }
  
  .card-body{
    color:white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .card-body:hover{
    color:var(--dark-purple);
  }

  .btn {
    width: 120px;  /* Adjust this width value according to your preferred size */
}

  .form-control{
    width: 200px;
  }

  .form-control-wide {
    width: 500px;
  }

  .button-group {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .button-group input {
    margin-left: 10px;
  }
  
  .button-group button {
    margin-right: 1rem;
  }
  
  .input[type='number'] {
    width: 100px;
  }

  .tube {
    display: flex;
    justify-content: space-between;
  }
  .tube-title {
    width: 50%; /* Half the screen width */
    margin: 0 auto; /* Centering horizontally */
    text-align: center;
  }

  .vertical-line {
    height: 100%; 
    width: 5px;
    background-color: #000;
  }

/* Styles for the toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;  /* Rounded corners */
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;  /* Circular shape */
}

input:checked + .slider {
  background-color: #4CAF50;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4CAF50;
}

input:checked + .slider:before {
  transform: translateX(15px);
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 900px;
}

.modal-config {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-config {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 50%;
  max-width: 400px;
}

.device-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

} 

.device-item {
  border: 1px solid #ccc;
  padding: 16px;
  width: 100%;
  display: flex; 
  /* align-items: center;
  justify-content: space-between; */
}

.device-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.device-toggle-label {
  margin-left: 10px;
}

.stateHistoryContainer {
  min-height: 300px;
  max-height: 300px;
  overflow-y:auto;
  border: 1px solid rgba(0, 0, 0, 0.37);
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 1px;
}

.stateHistoryContainer p {
  margin-bottom: 1px;
}

.stateHistoryContainer pre {
  max-width: 100%;           /* Set maximum width to prevent stretching */
  overflow-x: auto;          /* Enable horizontal scrolling */
  white-space: pre-wrap;     /* Allow normal text wrapping */
  word-wrap: break-word;     /* Break words that are too long */
}

.button-group .btn .time-input {
  width: 80px;        /* Set the width of the input */
  text-align: center; /* Center the text inside the input */
  border: none;       /* Optional: removes the border */
  outline: none;      /* Optional: removes the focus outline */
  margin-left: 0px;   /* Spacing between the label text and the input */
  border-radius: 7px;
}

.button-group .btn .time-input:focus {
  border: 1px solid gray; /* Optional: adds a border on focus */
}

.servo-debugger {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.servo-debugger h4 {
  margin-bottom: 10px;
}

.servo-control-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.servo-control-group select {
  width: 120px;
  background-color: #ffffff;
  padding: 1px 30px 1px 5px;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 12,0 6,7" style="fill:black" /></svg>'); /* A small SVG arrow */
  background-repeat: no-repeat;
  background-position: right 10px center;
  border: 1px solid #cccccc;
}

.servo-control-group label {
  width: 100px; /* Adjust as needed */
}

.servo-control-group select {
  width: 120px; /* Adjust as needed */
}

.btn-set-servo {
  margin-top: 10px; /* Spacing above the button */
}

/* Styles for the CheckBoxGrid and divider if needed */
.checkbox-grid {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.divider {
  min-width: 1px;
  background-color: #400140;
  margin: 0 10px;
  align-self: stretch;
}

/* Apply to the main container if needed */
.main-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* or 'center' if you want to vertically center the entire row */
}